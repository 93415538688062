<template>
  <!-- header (relative style) -->
        <header class="header header--static"  >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="header__content">
                            <button class="header__menu" type="button">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <router-link to="/" class="header__logo">
                            <h5 class="">السكري </h5>
                                <!-- <img style="z-index: -1;" src="static/img/zain_gray.png" alt=""> -->
                            </router-link>
                            <ul class="header__nav">
                                <li class="header__nav-item">
                                    <router-link to="/" class="header__nav-link"  role="button" id="dropdownMenu2"   aria-expanded="false">
                                        <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;" viewBox="0 0 20 20">
                                            <path fill="#fff"  d="M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z"></path>
                                        </svg>
                                        الصفحة الرئيسية
                                    </router-link>
                                </li>
                                <div class="header__nav-item">
                                    <a class="active exact-active header__nav-link" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right:0px;width:17px;margin-left: 5px;">
                                        <path fill="#fff" d="M7.228,11.464H1.996c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                              c0.723,0,1.308-0.586,1.308-1.308v-5.232C8.536,12.051,7.95,11.464,7.228,11.464z M7.228,17.351c0,0.361-0.293,0.654-0.654,0.654
                                              H2.649c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                              M17.692,11.464H12.46c-0.723,0-1.308,0.587-1.308,1.309v5.232c0,0.722,0.585,1.308,1.308,1.308h5.232
                                              c0.722,0,1.308-0.586,1.308-1.308v-5.232C19,12.051,18.414,11.464,17.692,11.464z M17.692,17.351c0,0.361-0.293,0.654-0.654,0.654
                                              h-3.924c-0.361,0-0.654-0.293-0.654-0.654v-3.924c0-0.361,0.293-0.654,0.654-0.654h3.924c0.361,0,0.654,0.293,0.654,0.654V17.351z
                                              M7.228,1H1.996C1.273,1,0.688,1.585,0.688,2.308V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232c0.723,0,1.308-0.585,1.308-1.308
                                              V2.308C8.536,1.585,7.95,1,7.228,1z M7.228,6.886c0,0.361-0.293,0.654-0.654,0.654H2.649c-0.361,0-0.654-0.292-0.654-0.654V2.962
                                              c0-0.361,0.292-0.654,0.654-0.654h3.924c0.361,0,0.654,0.292,0.654,0.654V6.886z M17.692,1H12.46c-0.723,0-1.308,0.585-1.308,1.308
                                              V7.54c0,0.723,0.585,1.308,1.308,1.308h5.232C18.414,8.848,19,8.263,19,7.54V2.308C19,1.585,18.414,1,17.692,1z M17.692,6.886
                                              c0,0.361-0.293,0.654-0.654,0.654h-3.924c-0.361,0-0.654-0.292-0.654-0.654V2.962c0-0.361,0.293-0.654,0.654-0.654h3.924
                                              c0.361,0,0.654,0.292,0.654,0.654V6.886z"></path>
                                        </svg>
                                        الفـــئــات
                                    </a>
                                    <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                        <li class="header__nav-item" v-for="publish in categoris" :key="publish.id">
                                            <router-link :to="{ name: 'Categorys', params: { catid: publish.id }}">{{ publish.name_ar }}</router-link>
                                        </li>
                                    </ul>
                                </div>
                                <template v-if="showHeader">  
                                <li class="header__nav-item">
                                    <router-link class="dropdownMenu2 header__nav-link" to="/Unsubscribes">
                                        <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"  viewBox="0 0 20 20">
							                <path fill="#fff" d="M12.443,9.672c0.203-0.496,0.329-1.052,0.329-1.652c0-1.969-1.241-3.565-2.772-3.565S7.228,6.051,7.228,8.02c0,0.599,0.126,1.156,0.33,1.652c-1.379,0.555-2.31,1.553-2.31,2.704c0,1.75,2.128,3.169,4.753,3.169c2.624,0,4.753-1.419,4.753-3.169C14.753,11.225,13.821,10.227,12.443,9.672z M10,5.247c1.094,0,1.98,1.242,1.98,2.773c0,1.531-0.887,2.772-1.98,2.772S8.02,9.551,8.02,8.02C8.02,6.489,8.906,5.247,10,5.247z M10,14.753c-2.187,0-3.96-1.063-3.96-2.377c0-0.854,0.757-1.596,1.885-2.015c0.508,0.745,1.245,1.224,2.076,1.224s1.567-0.479,2.076-1.224c1.127,0.418,1.885,1.162,1.885,2.015C13.961,13.689,12.188,14.753,10,14.753z M10,0.891c-5.031,0-9.109,4.079-9.109,9.109c0,5.031,4.079,9.109,9.109,9.109c5.031,0,9.109-4.078,9.109-9.109C19.109,4.969,15.031,0.891,10,0.891z M10,18.317c-4.593,0-8.317-3.725-8.317-8.317c0-4.593,3.724-8.317,8.317-8.317c4.593,0,8.317,3.724,8.317,8.317C18.317,14.593,14.593,18.317,10,18.317z"></path>
						                </svg>
                                         إلغــاء الاشتراك
                                    </router-link>
                                </li>
                                <li class="header__nav-item">
                                    <router-link class="dropdownMenu2 header__nav-link" to="/">
                                        <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"  viewBox="0 0 20 20">
							                <path fill="#fff" d="M12.443,9.672c0.203-0.496,0.329-1.052,0.329-1.652c0-1.969-1.241-3.565-2.772-3.565S7.228,6.051,7.228,8.02c0,0.599,0.126,1.156,0.33,1.652c-1.379,0.555-2.31,1.553-2.31,2.704c0,1.75,2.128,3.169,4.753,3.169c2.624,0,4.753-1.419,4.753-3.169C14.753,11.225,13.821,10.227,12.443,9.672z M10,5.247c1.094,0,1.98,1.242,1.98,2.773c0,1.531-0.887,2.772-1.98,2.772S8.02,9.551,8.02,8.02C8.02,6.489,8.906,5.247,10,5.247z M10,14.753c-2.187,0-3.96-1.063-3.96-2.377c0-0.854,0.757-1.596,1.885-2.015c0.508,0.745,1.245,1.224,2.076,1.224s1.567-0.479,2.076-1.224c1.127,0.418,1.885,1.162,1.885,2.015C13.961,13.689,12.188,14.753,10,14.753z M10,0.891c-5.031,0-9.109,4.079-9.109,9.109c0,5.031,4.079,9.109,9.109,9.109c5.031,0,9.109-4.078,9.109-9.109C19.109,4.969,15.031,0.891,10,0.891z M10,18.317c-4.593,0-8.317-3.725-8.317-8.317c0-4.593,3.724-8.317,8.317-8.317c4.593,0,8.317,3.724,8.317,8.317C18.317,14.593,14.593,18.317,10,18.317z"></path>
						                </svg>
                                        {{ $cookie.getCookie("msisdn") }}
                                    </router-link>
                                </li>
                                </template>
                                <template v-else>
                                <li class="header__nav-item" >
                                    <router-link class="dropdownMenu2 header__nav-link" to="/Login">
                                        <svg class="svg-icon" style="margin-right:0px;width:17px;margin-left: 5px;"  viewBox="0 0 20 20">
							                <path fill="#fff" d="M12.443,9.672c0.203-0.496,0.329-1.052,0.329-1.652c0-1.969-1.241-3.565-2.772-3.565S7.228,6.051,7.228,8.02c0,0.599,0.126,1.156,0.33,1.652c-1.379,0.555-2.31,1.553-2.31,2.704c0,1.75,2.128,3.169,4.753,3.169c2.624,0,4.753-1.419,4.753-3.169C14.753,11.225,13.821,10.227,12.443,9.672z M10,5.247c1.094,0,1.98,1.242,1.98,2.773c0,1.531-0.887,2.772-1.98,2.772S8.02,9.551,8.02,8.02C8.02,6.489,8.906,5.247,10,5.247z M10,14.753c-2.187,0-3.96-1.063-3.96-2.377c0-0.854,0.757-1.596,1.885-2.015c0.508,0.745,1.245,1.224,2.076,1.224s1.567-0.479,2.076-1.224c1.127,0.418,1.885,1.162,1.885,2.015C13.961,13.689,12.188,14.753,10,14.753z M10,0.891c-5.031,0-9.109,4.079-9.109,9.109c0,5.031,4.079,9.109,9.109,9.109c5.031,0,9.109-4.078,9.109-9.109C19.109,4.969,15.031,0.891,10,0.891z M10,18.317c-4.593,0-8.317-3.725-8.317-8.317c0-4.593,3.724-8.317,8.317-8.317c4.593,0,8.317,3.724,8.317,8.317C18.317,14.593,14.593,18.317,10,18.317z"></path>
						                </svg>
                                         تسجيل دخول
                                    </router-link>
                                </li>
                                <li class="header__nav-item" >
                                    <router-link to="/Subscribes" class="header__nav-link" role="button" id="dropdownMenu2" aria-expanded="false">
                                        <svg class="svg-icon" viewBox="0 0 20 20" style="margin-right: 0px;width: 17px;margin-left: 5px;">
							                <path fill="#fff" d="M18.037,18.517L16.787,4.646c-0.034-0.38-0.355-0.672-0.735-0.672h-2.1c-0.258-1.968-1.93-3.499-3.967-3.499c-2.039,0-3.71,1.531-3.967,3.499H3.921c-0.381,0-0.7,0.291-0.735,0.672L1.915,18.72c-0.02,0.206,0.049,0.411,0.19,0.564c0.138,0.152,0.338,0.241,0.545,0.241h14.67c0.012-0.002,0.02-0.002,0.03,0c0.408,0,0.738-0.331,0.738-0.738C18.088,18.692,18.07,18.601,18.037,18.517z M9.985,1.951c1.225,0,2.25,0.87,2.49,2.023h-4.98C7.735,2.821,8.76,1.951,9.985,1.951zM3.457,18.049l1.139-12.6h10.782l1.135,12.6H3.457z"></path></svg>
                                            إشتــرك الأن
                                    </router-link >
                                </li>
                                </template>
                                <!-- <li class="header__nav-item" v-else> -->
                                <li class="header__nav-item">
                                    <a class="dropdown-item" href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                                    </a>
                                </li>
                            </ul>
                            <div class="header__actions" style="margin-left: 8vh;">
                                <form class="header__form" @submit.prevent="SearchData()">
                                    <input class="header__form-input" v-model="search" type="text" placeholder="بحث ...">
                                    <button class="header__form-btn" type="submit"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg></button>
                                    <button type="button" class="header__form-close"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.3345 0.000183105H5.66549C2.26791 0.000183105 0.000488281 2.43278 0.000488281 5.91618V14.0842C0.000488281 17.5709 2.26186 20.0002 5.66549 20.0002H14.3335C17.7381 20.0002 20.0005 17.5709 20.0005 14.0842V5.91618C20.0005 2.42969 17.7383 0.000183105 14.3345 0.000183105ZM5.66549 1.50018H14.3345C16.885 1.50018 18.5005 3.23515 18.5005 5.91618V14.0842C18.5005 16.7653 16.8849 18.5002 14.3335 18.5002H5.66549C3.11525 18.5002 1.50049 16.7655 1.50049 14.0842V5.91618C1.50049 3.23856 3.12083 1.50018 5.66549 1.50018ZM7.07071 7.0624C7.33701 6.79616 7.75367 6.772 8.04726 6.98988L8.13137 7.06251L9.99909 8.93062L11.8652 7.06455C12.1581 6.77166 12.6329 6.77166 12.9258 7.06455C13.1921 7.33082 13.2163 7.74748 12.9984 8.04109L12.9258 8.12521L11.0596 9.99139L12.9274 11.8595C13.2202 12.1524 13.2202 12.6273 12.9273 12.9202C12.661 13.1864 12.2443 13.2106 11.9507 12.9927L11.8666 12.9201L9.99898 11.052L8.13382 12.9172C7.84093 13.2101 7.36605 13.2101 7.07316 12.9172C6.80689 12.6509 6.78269 12.2343 7.00054 11.9407L7.07316 11.8566L8.93843 9.99128L7.0706 8.12306C6.77774 7.83013 6.77779 7.35526 7.07071 7.0624Z"/></svg></button>
                                </form>
                                <button class="header__search" type="button" style="margin-left: 10vh;">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                                </button>
                                <!-- <loading v-model:active="visible" :can-cancel="true"></loading> -->
                                <router-link to="/" class="header__user pointer" >
                                    <img style="width: 100px;z-index: -1;" src="../../assets/image/zain_gray.png" alt="">
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- end header -->
</template>

<script>
import { ref } from '@vue/reactivity';
import { onMounted, watchEffect } from '@vue/runtime-core';
import { HTTPDSP } from '@/Api/http-dsp';
import { HTTP } from '@/Api/http-common';
import { useRoute, useRouter } from 'vue-router';
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppHeader',
//    data() {
//     return {
//         search: ""
//       };
//     },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const showHeader = ref(true);
        const search = ref([]);
        const categoris = ref([]);

        watchEffect(() => {
            if (cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie('msisdn');
                        // cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            //    cookie.removeCookie("msisdn");
            }
        });  
        const GetCategories = async () => {
          await HTTP.get('GetAllCategories.php').then(res => {
                categoris.value = res.data.Categories;
            })
        }
        onMounted(() => {
            GetCategories();
        }); 

        const SearchData = () => {
            router.push({ name: "Searchs", params: { search_id: search.value } });
        }
        return { search, categoris, showHeader, SearchData };
    },
    mounted() {
         let externalScript = document.createElement('script')
             externalScript.setAttribute('src', '/static/js/main.js')
             document.head.appendChild(externalScript)
    }
}
</script>

<style>
a:hover {
    color: #0056b3;
}
.header__nav-menu a:hover {
    color: #87a1ef;
}
.pointer {
  cursor: pointer;
}
</style>
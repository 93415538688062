import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import lazyPlugin from 'vue3-lazy'

fastclick.attach(document.body)
const loadimage = require('./assets/image/Loading.gif')
// const errorimage = require('./assets/image/Loading.gif')

const app = createApp(App);
app.use(store)
app.use(router)
router.app = app
app.use(VueCookieNext)
app.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 30,
    newestOnTop: true,
    timeout: 4000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: false,
    icon: true,
    rtl: true
})
app.use(lazyPlugin, {
    preLoad: 1.3,
    loading: loadimage,
    attempt: 1
})
app.mount('#app')
